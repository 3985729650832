import axios from '@/plugins/axios';
import { STATUS } from './constants';

class DistributeManageApi {
  static getDevices(
    page = 1,
    limit = 10,
    buyerGroupType,
    sellerGroupType,
    buyerName,
    status,
    dateRange,
    startDate,
    endDate,
  ) {
    return axios({
      url: '/api/train/distribute/page',
      data: {
        page,
        limit,
        buyer_group_type: buyerGroupType,
        seller_group_type: sellerGroupType,
        buyer_name: buyerName,
        status,
        start_date: startDate,
        end_date: endDate,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询设备列表',
        },
      },
    });
  }

  static createDistribute(
    {
      storeId,
      buyerId,
      num,
      orderType,
      buyerGroupType,
    },
  ) {
    return axios({
      url: '/api/train/distribute/add',
      data: {
        store_id: storeId,
        buyer_id: buyerId,
        num,
        order_type: orderType,
        buyer_group_type: buyerGroupType,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '分配任务',
        },
      },
    });
  }

  static editDevice(
    id = NaN,
    name = '',
    hardwareVersion = '',
    purpose = '',
    status = STATUS.DISABLE,
  ) {
    return axios({
      url: '/api/product/pv3/edit',
      data: {
        id,
        name,
        hardware_version: hardwareVersion,
        purpose,
        status,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '编辑设备',
        },
      },
    });
  }

  static distributeDevice(
    id = NaN,
    channelId = NaN,
    storeId = NaN,
  ) {
    return axios({
      url: `/api/product/pv3/${id}/distribute`,
      data: {
        channel_id: channelId,
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '分配设备',
        },
      },
    });
  }

  static returnDevice(
    id = NaN,
  ) {
    return axios({
      url: `/api/train/distribute/${id}/cancel`,
      method: 'post',
      headers: {
        meta: {
          desc: '撤销',
        },
      },
    });
  }

  static getReturn(
    id = NaN,
  ) {
    return axios({
      url: `api/train/distribute/${id}/cancel`,
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取设备详情',
        },
      },
    });
  }

  static getSubCustomerOfCurrentUser() {
    return axios({
      url: '/api/customer/bylogin/list',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询用户列表',
        },
      },
    });
  }

  static getFreqcard(id) {
    return axios({
      url: `/api/store/${id}/freqcard`,
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店次数',
        },
      },
    });
  }

  static getCustomFreqcard(data) {
    return axios({
      url: '/api/customer/store/freqcard',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店次数',
        },
      },
    });
  }

  static getChannelFreqcard(id) {
    return axios({
      url: `/api/channel/${id}/freqcard`,
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询渠道次数',
        },
      },
    });
  }

  static getAccountFreqcard() {
    return axios({
      url: '/api/sys/account/freqcard',
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询登录账号剩余次卡',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/train/distribute/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default DistributeManageApi;
