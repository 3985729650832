<template>
  <div class="training-distribution">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchDistribute"/>
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="DISTRIBUTION_ACTION.CREATE"
          type="warning"
          @click="() => onDeviceAction(DISTRIBUTION_ACTION.CREATE, true)">
          新建分配
        </Button>
      </app-spacing>
      <app-export-bar
        @onExport="onExport"
        @onExportAll="onExportAll" />
    </app-block-flex>
    <app-data-table
      :columns="DEVICE_COLUMNS"
      :data="devices"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onPageConfigChange="fetchDistribute">
      <template
        slot-scope="{ row }"
        slot="buyer_group_type">
        {{ BUYS_TEXT[row.buyer_group_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="seller_group_type">
        {{ BUYS_TEXT[row.seller_group_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="order_type">
        {{ BUYS_TEXT[row.order_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ DIS_STATUS[row.status] }}
      </template>
      <app-spacing
        spacing="sm"
        class="table-action"
        slot-scope="{ row }"
        slot="action">
        <Button
          v-if="permissions[DISTRIBUTION_ACTION.RETURN]"
          :disabled="!row.status"
          type="error"
          @click="() => onDeviceAction(DISTRIBUTION_ACTION.RETURN, true, row.id)">
          撤销
        </Button>
      </app-spacing>
    </app-data-table>

    <app-modal
      :value="distributionModalConfig.visible"
      :title="distributionActionText"
      @on-ok="onModalConfirm"
      @on-cancel="() => onDeviceAction(null, false)">
      <Form
        :model="distributionModalData"
        :label-width="80"
        >
        <template v-if="distributionModalConfig.action === DISTRIBUTION_ACTION.CREATE">
          <FormItem label="用户类型">
            <Select
              v-model="distributionModalData.buyerGroupType"
              @on-change="getDataOption"
              placeholder="请选择类型">
              <Option
                v-for="option in this.statusOptions"
                :value="option.value"
                :key="option.value">
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem v-if="distributionModalData.buyerGroupType === 'channel'" label="渠道名称">
            <Select
              filterable
              v-model="distributionModalData.buyerId"
              @on-select="(v) => getChannelFreqcard(v.value)"
              placeholder="请选择渠道名称">
              <Option
                v-for="option in distributionModalConfig.channelOptions"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem v-if="distributionModalData.buyerGroupType === 'customer'" label="用户名称">
            <Select
              filterable
              @on-select="(V)=>getCustomStoreOptions(V.value)"
              v-model="distributionModalData.buyerId"
              placeholder="请选择用户名称">
              <Option
                v-for="option in distributionModalConfig.customOptions"
                :value="option.id"
                :key="option.id">
                {{ option.nick_name }}（{{ option.mobile }}）
              </Option>
            </Select>
          </FormItem>
          <FormItem
            v-if="distributionModalData.buyerGroupType === 'store'
            && distributionModalData.buyerGroupType" label="门店名称">
            <Select
              filterable
              clearable
              v-model="distributionModalData.buyerId"
              @on-select="(v) => getFreqcard(v.value)"
              placeholder="请选择门店名称">
              <Option
                v-for="option in distributionModalConfig.storeOptions"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            v-if="distributionModalData.buyerGroupType === 'customer'
            && this.userType === 'ADMIN' && distributionModalData.buyerGroupType" label="门店名称">
            <Select
              filterable
              :disabled="!distributionModalData.buyerId"
              v-model="distributionModalData.storeId"
              @on-select="(v) => getFreqcard(v.value)"
              placeholder="请选择用户所在门店名称">
              <Option
                v-for="option in distributionModalConfig.customstoreOptions"
                :value="option.store_id"
                :key="option.store_id">
                {{ option.store_name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="剩余次数">
            {{ distributionModalConfig.count }}
          </FormItem>
          <FormItem label="次数">
            <Input
              placeholder="正数为增加,负数为减少"
              v-model="distributionModalData.num"
            />
          </FormItem>
          <FormItem label="充值类型">
            <Select
              v-model="distributionModalData.orderType"
              placeholder="请选择充值类型">
              <Option
                v-for="option in distributionModalConfig.orderTypeOptions"
                :value="option.value"
                :key="option.value">
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
        </template>
        <template v-else-if="distributionModalConfig.action === DISTRIBUTION_ACTION.RETURN">
          撤销后，对应减去充值次数，是否继续操作？
        </template>
      </Form>
    </app-modal>
  </div>
</template>

<script>
import {
  DEVICE_COLUMNS,
  ALLOWED_PERMISSION,
  DISTRIBUTION_ACTION,
  DISTRIBUTION_ACTION_TEXT,
  REVERSE_STATUS_TEXT,
  BUYS_STATUS_OPTIONS,
  BUYS_TEXT,
  DIS_STATUS,
  REVERSE_STATUS_OPTION,
  ORDER_STATUS_OPTIONS,
  CHANNEL_STATUS_OPTIONS,
  STORE_STATUS_OPTIONS,
} from './constants';
import DistributeManageApi from './training-distribution.api';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';
import StoreManageApi from '@/pages/business/store-manage/store-manage.api';
import CustomerAccountManageApi from '@/pages/account/customer-account-manage/customer-account-manage.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'training-distribution',
  computed: {
    distributionActionText() {
      return `${DISTRIBUTION_ACTION_TEXT[this.distributionModalConfig.action]}${this.distributionModalConfig.action === DISTRIBUTION_ACTION.CREATE && `-可分配次数：${this.distributionModalConfig.accountCount?.toString()}`}`;
    },
    userType() {
      return this.$store.getters.userType;
    },
    storeId() {
      return this.$store.getters.storeId;
    },
    channelId() {
      return this.$store.getters.channelId;
    },
    queryConfig() {
      return [
        {
          fieldName: 'buyerGroupType',
          name: '买方类型',
          type: 'select',
          options: BUYS_STATUS_OPTIONS,
        },
        {
          fieldName: 'buyerName',
          name: '买方',
        },
        {
          fieldName: 'status',
          name: '状态',
          type: 'select',
          options: REVERSE_STATUS_OPTION,
        },
        {
          fieldName: 'dateRange',
          name: '时间',
          type: 'dateRange',
        },
      ];
    },
    statusOptions() {
      switch (this.userType) {
        case 'ADMIN': return BUYS_STATUS_OPTIONS;
        case 'CHANNEL': return CHANNEL_STATUS_OPTIONS;
        case 'OUTLET': return STORE_STATUS_OPTIONS;
        default: return BUYS_STATUS_OPTIONS;
      }
    },
  },
  data() {
    return {
      BUYS_TEXT,
      DIS_STATUS,
      DEVICE_COLUMNS,
      ALLOWED_PERMISSION,
      DISTRIBUTION_ACTION,
      DISTRIBUTION_ACTION_TEXT,
      REVERSE_STATUS_TEXT,
      BUYS_STATUS_OPTIONS,
      ORDER_STATUS_OPTIONS,
      REVERSE_STATUS_OPTION,
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        buyerGroupType: '',
        buyerName: '',
        status: '',
        dateRange: ['', ''],
      },
      devices: [],
      distributionModalConfig: {
        visible: false,
        action: null,
        orderTypeOptions: ORDER_STATUS_OPTIONS,
        channelOptions: [],
        storeOptions: [],
        customOptions: [],
        customstoreOptions: [],
        count: 0,
        accountCount: 0,
      },
      distributionModalData: {
        id: NaN,
        storeId: NaN,
        buyerId: '',
        num: '',
        orderType: '',
        buyerGroupType: '',
      },
    };
  },
  created() {
    this.fetchDistribute();
  },
  methods: {
    async fetchDistribute() {
      const {
        page, limit, buyerGroupType,
        buyerName,
        status,
        dateRange,
        sellerGroupType,
      } = this.queryData;
      const [startDate, endDate] = dateRange;
      const {
        count = 0,
        results = [],
      } = await DistributeManageApi.getDevices(
        page,
        limit,
        buyerGroupType,
        sellerGroupType,
        buyerName,
        status,
        dateRange,
        startDate,
        endDate,
      );
      this.devices = results;
      this.$set(this.queryData, 'count', count);
    },
    async returnDevice(deviceId) {
      await DistributeManageApi.returnDevice(deviceId);
    },
    async onDeviceAction(action = DISTRIBUTION_ACTION.CREATE, visible = false, id) {
      const accountCount = await DistributeManageApi.getAccountFreqcard();

      this.distributionModalData = {
        storeId: id,
        buyerId: '',
        num: '',
        orderType: '',
        buyerGroupType: '',
      };
      this.distributionModalConfig = {
        ...this.distributionModalConfig,
        count: 0,
        accountCount,
        action,
        visible,
      };
    },
    async onModalConfirm() {
      const {
        storeId,
        buyerId,
        num,
        orderType,
        buyerGroupType,
      } = this.distributionModalData;

      switch (this.distributionModalConfig.action) {
        case DISTRIBUTION_ACTION.CREATE:
          await DistributeManageApi.createDistribute({
            storeId,
            buyerId,
            num,
            orderType,
            buyerGroupType,
          });
          break;
        case DISTRIBUTION_ACTION.RETURN:
          await this.returnDevice(storeId);
          break;
        default:
          break;
      }

      await this.onDeviceAction(null);

      await this.fetchDistribute();
    },
    async getDataOption() {
      this.$set(this.distributionModalData, 'buyerId', NaN);
      this.$set(this.distributionModalConfig, 'count', 0);

      switch (this.distributionModalData.buyerGroupType) {
        case 'channel':
          // eslint-disable-next-line no-case-declarations
          let channelOptions = await ChannelManageApi.getChannelsByChannelName();
          if (this.userType === 'CHANNEL') {
            channelOptions = channelOptions.filter((option) => this.channelId !== option.id);
          }
          this.$set(this.distributionModalConfig, 'channelOptions', channelOptions);
          break;
        case 'store':
          // eslint-disable-next-line no-case-declarations
          let storeOptions = await StoreManageApi.getStoresByStoreNameOrChannelId();
          if (this.userType === 'OUTLET') {
            storeOptions = storeOptions.filter((option) => this.storeId !== option.id);
          }
          this.$set(this.distributionModalConfig, 'storeOptions', storeOptions);
          break;
        case 'customer':
          // eslint-disable-next-line no-case-declarations
          const customOptions = await DistributeManageApi.getSubCustomerOfCurrentUser();
          this.$set(this.distributionModalConfig, 'customOptions', customOptions);
          break;
        default:
          break;
      }
    },
    async getCustomStoreOptions(id) {
      if (this.userType === 'OUTLET' && id) {
        const count = await
        DistributeManageApi.getCustomFreqcard(
          {
            customer_id: id,
            store_id: this.storeId,
          },
        );
        this.$set(this.distributionModalConfig, 'count', count);
      }
      if (this.userType === 'OUTLET') return;
      const customstoreOptions = await
      CustomerAccountManageApi.getBelongStores(id);
      this.$set(this.distributionModalConfig, 'customstoreOptions', customstoreOptions);
    },
    async getFreqcard(id) {
      if (this.distributionModalData.buyerGroupType === 'customer') {
        if (this.userType === 'ADMIN') {
          const count = await
          DistributeManageApi.getCustomFreqcard(
            {
              customer_id: this.distributionModalData.buyerId,
              store_id: id,
            },
          );
          this.$set(this.distributionModalConfig, 'count', count);
        } else {
          const count = await
          DistributeManageApi.getCustomFreqcard(
            {
              customer_id: this.distributionModalData.buyerId,
              store_id: this.storeId,
            },
          );
          this.$set(this.distributionModalConfig, 'count', count);
        }
      } else {
        const count = await
        DistributeManageApi.getFreqcard(id);
        this.$set(this.distributionModalConfig, 'count', count);
      }
    },
    async getChannelFreqcard(id) {
      const count = await
      DistributeManageApi.getChannelFreqcard(id);
      this.$set(this.distributionModalConfig, 'count', count);
    },
    async onExport() {
      const {
        page,
        limit,
        buyerGroupType,
        buyerName,
        status,
        dateRange = ['', ''],
      } = this.queryData;
      const [startDate, endDate] = dateRange;

      const key = await DistributeManageApi.export({
        page,
        limit,
        buyer_group_type: buyerGroupType,
        buyer_name: buyerName,
        status,
        start_date: startDate,
        end_date: endDate,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await DistributeManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>
