import DataTransferUtil from '../../../utils/data-transfer.util';

export const CUSTOMER_COLUMNS = [
  {
    title: '用户名',
    key: 'nick_name',
    fixed: 'left',
    width: 100,
  },
  {
    title: '手机号',
    key: 'mobile',
    width: 200,
  },
  {
    title: '账号类型',
    key: 'type',
    slot: 'type',
    width: 100,
  },
  {
    title: '性别',
    key: 'sex',
    slot: 'sex',
    width: 100,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    width: 100,
  },
  {
    title: '充值总次数', // 渠道账号没有该字段
    key: 'num',
    width: 120,
  },
  {
    title: '剩余次数', // 渠道账号没有该字段
    key: 'remain_freq_card',
    width: 100,
  },
  {
    title: '时效卡',
    key: 'time_freq_card_num',
    slot: 'time_freq_card_num',
    width: 100,
  },
  {
    title: '创建时间',
    key: 'create_time',
    minWidth: 180,
  },
  {
    title: '是否会员',
    key: 'jxw_vip_expire_time',
    slot: 'jxw_vip_expire_time',
    minWidth: 180,
  },
  {
    title: '关联使用用户',
    key: 'member_num',
    slot: 'member_num',
    width: 140,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    minWidth: 100,
    maxWidth: 240,
    align: 'center',
    fixed: 'right',
  },
];

export const STATUS = {
  ENABLE: 1,
  DISABLE: 0,
  ALL: 'all',
};

export const STATUS_TEXT = {
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const SEX = {
  MALE: 1,
  FEMALE: 0,
};

export const SEX_TEXT = {
  [SEX.MALE]: '男',
  [SEX.FEMALE]: '女',
};

export const ACCOUNT_TYPE = {
  NO_CATEGORY: 0,
  PERSONAL: 1,
  STORE: 2,
};
export const ALLOWED_PERMISSION = {
  EDIT: 'account:consumer:edit',
  PARENTSTORE: 'account:consumer:store',
  REPORT: 'account:customer:train:record',
  TOGGLESTATUS: 'account:consumer:status-toggle',
  FIX: 'account:consumer:prescription:edit',
  CREATE: '换绑门店',
};
export const CUS_ACTION = {
  ...ALLOWED_PERMISSION,
};
export const CUS_ACTION_TEXT = {
  [CUS_ACTION.CREATE]: '换绑门店',
  [CUS_ACTION.EDIT]: '编辑',
  [CUS_ACTION.PARENTSTORE]: '归属门店',
  [CUS_ACTION.REPORT]: '训练报告',
  [CUS_ACTION.FIX]: '修改处方',
  [CUS_ACTION.TOGGLESTATUS]: '停用',
};
export const ACCOUNT_TYPE_TEXT = {
  [ACCOUNT_TYPE.NO_CATEGORY]: '未分类',
  [ACCOUNT_TYPE.PERSONAL]: '个人',
  [ACCOUNT_TYPE.STORE]: '门店',
};
export const columnsArr = [
  {
    title: '序号',
    key: 'slotName',
    slot: 'slotName',
  },
  {
    title: '门店名称',
    key: 'store_name',
  },
  {
    title: '剩余次数',
    key: 'remain_freq_card',
  },
];
export const reportColumnsArr = [
  {
    title: '序号',
    key: 'slotName',
    width: 80,
    slot: 'slotName',
  },
  {
    title: '训练时间',
    key: 'start_time',
    width: 180,
  },
  {
    title: '训练方案',
    minWidth: 200,
    key: 'train_title',
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    fixed: 'right',
  },
];

export const CHART_TYPE = {
  FOCUS_ON: 'red',
  CAN_IMPROVE: 'yellow',
};

export const CHART_TYPE_CODE = {
  [CHART_TYPE.FOCUS_ON]: -0.50000000000000,
  [CHART_TYPE.CAN_IMPROVE]: -0.5000000000001,
};

export const CHART_TYPE_TEXT = {
  [CHART_TYPE_CODE[CHART_TYPE.FOCUS_ON]]: 'A',
  [CHART_TYPE_CODE[CHART_TYPE.CAN_IMPROVE]]: 'B',
};

export const CHART_TYPE_COLOR = {
  [CHART_TYPE_CODE[CHART_TYPE.FOCUS_ON]]: '#000',
  [CHART_TYPE_CODE[CHART_TYPE.CAN_IMPROVE]]: '#000',
};

export const RELATION = {
  // 自己
  DEFAULT: 0,
  // 父亲
  FATHER: 1,
  // 母亲
  MOTHER: 2,
  // 丈夫
  HUSBAND: 3,
  // 妻子
  WIFE: 4,
  // 儿子
  SON: 5,
  // 女儿
  DAUGHTER: 6,
  // 其他
  OTHER: 7,
};

export const RELATION_TEXT = {
  [RELATION.DEFAULT]: '自己',
  [RELATION.FATHER]: '父亲',
  [RELATION.MOTHER]: '母亲',
  [RELATION.HUSBAND]: '丈夫',
  [RELATION.WIFE]: '妻子',
  [RELATION.SON]: '儿子',
  [RELATION.DAUGHTER]: '女儿',
  [RELATION.OTHER]: '其他',
};

export const RELATION_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(RELATION_TEXT);
