import axios from '@/plugins/axios';
import { STATUS } from './constants';

class CustomerAccountManageApi {
  static getCustomers(
    page = 1,
    limit = 10,
    nickName = '',
    storeId = NaN,
    mobile = '',
    type = '',
    hasTimeCard = NaN,
    memberShip = NaN,
    status = STATUS.ALL,
  ) {
    return axios({
      url: '/api/customer/page',
      data: {
        page,
        limit,
        nick_name: nickName,
        store_id: storeId,
        mobile,
        status,
        type,
        hasTimeCard,
        memberShip,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者管理账号列表',
        },
      },
    });
  }

  static getBelongStores(customerId = NaN) {
    return axios({
      url: '/api/customer/store/list',
      data: {
        customer_id: customerId, // TODO: 实际还需要模糊搜索
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询归属门店列表',
        },
      },
    });
  }

  static getCustomerStoreAll(customerId = NaN) {
    return axios({
      url: '/api/customer/store/all',
      data: {
        customer_id: customerId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询全部归属门店',
        },
      },
    });
  }

  static changeCustomerStore(type = NaN, customerId = NaN, store_id = NaN) {
    return axios({
      url: '/api/customer/store/change',
      data: {
        type,
        customer_id: customerId,
        store_id,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '更新消费者归属门店',
        },
      },
    });
  }

  static getRecordList(customerId = NaN, startDate, endDate) {
    return axios({
      url: `/api/customer/${customerId}/train/record/list`,
      method: 'post',
      data: {
        start_date: startDate, end_date: endDate,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询训练记录列表',
        },
      },
    });
  }

  static toggleStatus(customerId, status) {
    return axios({
      url: `/api/customer/${customerId}/status/toggle`,
      method: 'post',
      data: {
        status,
      },
      headers: {
        meta: {
          desc: '操作',
        },
      },
    });
  }

  static customerEdit(id, nickName, mobile, birthday, memberLimit) {
    return axios({
      url: '/api/customer/edit',
      method: 'post',
      data: {
        id,
        nick_name: nickName,
        mobile,
        birthday,
        member_limit: memberLimit,
      },
      headers: {
        meta: {
          desc: '编辑',
        },
      },
    });
  }

  static rebind(selectStoreIds, bindStoreId, storeId) {
    return axios({
      url: '/api/customer/rebind',
      method: 'post',
      data: {
        sub_ids: selectStoreIds,
        old_store_id: bindStoreId,
        new_store_id: storeId,
      },
      headers: {
        meta: {
          desc: '换绑',
        },
      },
    });
  }

  static getPrescriptions(id) {
    return axios({
      url: '/api/app/customer/prescription/info',
      method: 'post',
      data: {
        customer_id: id,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询处方列表',
        },
      },
    });
  }

  static savePrescriptions(customerId, data) {
    return axios({
      url: '/api/customer/prescription/edit',
      method: 'post',
      data: { ...data, id: customerId },
      headers: {
        meta: {
          desc: '保存处方',
        },
      },
    });
  }

  static prescriptionHistories(customerId) {
    return axios({
      url: '/api/app/log/prescription/ofcustomer/list',
      method: 'post',
      data: { customer_id: customerId },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询处方历史',
        },
      },
    });
  }

  static prescriptionHistoryDetail(logId) {
    return axios({
      url: `/api/app/log/prescription/${logId}/info`,
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询历史处方详情',
        },
      },
    });
  }

  static useOldRecordReplacePrescription(logId) {
    return axios({
      url: `/api/customer/log/prescription/${logId}/use`,
      method: 'post',
      headers: {
        meta: {
          desc: '替换方案',
        },
      },
    });
  }

  static getReportDetail(reportId) {
    return axios({
      url: `api/train/record/${reportId}/detail`,
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取训练报告详情',
        },
      },
    });
  }

  static export({ ...data }) {
    return axios({
      url: '/api/customer/export',
      method: 'post',
      data: {
        page: data.page,
        limit: data.limit,
        nick_name: data.nickName,
        store_id: data.storeId,
        mobile: data.mobile,
        status: data.status,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }

  // 获取家庭子账号
  static getFamilyMembers(
    customerId = NaN,
  ) {
    return axios({
      url: '/api/customer/family-member/all',
      data: {
        customer_id: customerId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者子账号列表',
        },
      },
    });
  }

  // 编辑子账号
  static editFamilyMember(id, nickName, sex, birthday, relation, deleteTime) {
    return axios({
      url: '/api/customer/family-member/edit',
      method: 'post',
      data: {
        id,
        nick_name: nickName,
        sex,
        birthday,
        relation,
        delete_time: deleteTime,
      },
      headers: {
        meta: {
          desc: '编辑子账号',
        },
      },
    });
  }

  // 获取家庭子账号
  static getTimeFreqCards(
    customerId = NaN,
  ) {
    return axios({
      url: '/api/customer/time-freq-card/all',
      data: {
        customer_id: customerId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者时效卡列表',
        },
      },
    });
  }
}

export default CustomerAccountManageApi;
