import DataTransferUtil from '@/utils/data-transfer.util';

export const DEVICE_COLUMNS = [
  {
    title: '买方类型',
    key: 'buyer_group_type',
    slot: 'buyer_group_type',
    minWidth: 100,
  },
  {
    title: '卖方类型',
    key: 'seller_group_type',
    slot: 'seller_group_type',
    minWidth: 100,
  },
  {
    title: '买方',
    key: 'buyer_name',
    minWidth: 100,
  }, {
    title: '卖方',
    key: 'seller_name',
    minWidth: 100,
  }, {
    title: '充值类型',
    slot: 'order_type',
    key: 'order_type',
    width: 100,
  }, {
    title: '订单号',
    key: 'order_no',
    width: 140,
    fixed: 'left',
  }, {
    title: '次数',
    key: 'num',
    minWidth: 80,
  }, {
    title: '操作人',
    key: 'opt_nick_name',
    minWidth: 140,
  }, {
    title: '订单状态',
    key: 'status',
    slot: 'status',
    width: 100,
  }, {
    title: '创建时间',
    key: 'create_time',
    minWidth: 180,
  }, {
    title: '撤销时间',
    key: 'cancel_time',
    minWidth: 180,
  },
  {
    title: '操作',
    slot: 'action',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
];

export const ALLOWED_PERMISSION = {
  RETURN: 'train:distribute:cancel',
};

export const DISTRIBUTION_ACTION = {
  CREATE: 'train:distribute:add',
  ...ALLOWED_PERMISSION,
};

export const DISTRIBUTION_ACTION_TEXT = {
  [DISTRIBUTION_ACTION.CREATE]: '新建分配',
  [DISTRIBUTION_ACTION.RETURN]: '撤销',
};

export const STATUS = {
  ALL: 'all',
  ENABLE: 1,
  DISABLE: 0,
};

export const BASIC_STATUS_TEXT = {
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const STATUS_TEXT = {
  [STATUS.ALL]: '全部',
  ...BASIC_STATUS_TEXT,
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const ACTIVE_STATUS = {
  ALL: 'all',
  UN_ACTIVE: 0,
  ACTIVE: 1,
  RETURN: 2,
};

export const BUYS_STATUS = {
  channel: 'channel',
  store: 'store',
  customer: 'customer',
  send: 0,
  bug: 1,
  SYSTEM: 'sys',
};

export const BUYS_TEXT = {
  [BUYS_STATUS.channel]: '渠道',
  [BUYS_STATUS.store]: '门店',
  [BUYS_STATUS.customer]: '消费者',
  [BUYS_STATUS.send]: '赠送',
  [BUYS_STATUS.bug]: '购买',
  [BUYS_STATUS.SYSTEM]: '运营',
};
export const DIS_STATUS = {
  [STATUS.ALL]: '全部',
  [STATUS.ENABLE]: '成功',
  [STATUS.DISABLE]: '撤销',
};
export const SYS_STATUS_OP = {
  channel: '渠道',
  store: '门店',
  customer: '消费者',
};
export const CHANNEL_STATUS_OP = {
  channel: '渠道',
  store: '门店',
};
export const STORE_STATUS_OP = {
  store: '门店',
  customer: '消费者',
};
export const BUYS_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(SYS_STATUS_OP);
export const CHANNEL_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(CHANNEL_STATUS_OP);
export const STORE_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(STORE_STATUS_OP);
export const ORDER_STATUS_OP = {
  0: '赠送',
  1: '购买',
};
export const ORDER_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(ORDER_STATUS_OP);
export const REVERSE_STATUS_OPTION = DataTransferUtil.enumTransfer2Options(DIS_STATUS);
